<template>
  <FormulateForm
    :key="key"
    name="publicationActionCreate"
    @submit="submit"
  >
    <div class="tw-mt-4 tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-w-full tw-items-start">
      <FormulateInput
        type="date"
        name="start_date"
        label="Startdatum"
        placeholder="YYYY-MM-DD"
        :show-required-label="true"
        validation="bail|required|date:YYYY-MM-DD|validEndDate"
        :validation-rules="{ validEndDate }"
        :validation-messages="{
          validEndDate:
            'Startdatum mag niet na de einddatum liggen.',
        }"
        outer-class="tw-flex-grow"
      />
      <FormulateInput
        type="date"
        name="end_date"
        label="Einddatum"
        placeholder="YYYY-MM-DD"
        validation="bail|optional|date:YYYY-MM-DD|validEndDate"
        :validation-rules="{ validEndDate }"
        :validation-messages="{
          validEndDate:
            'Einddatum kan niet voor de startdatum vallen.',
        }"
        outer-class="tw-flex-grow"
      />
      <FormulateInput
        type="autocomplete"
        auto-complete-type="publicationTypes"
        :stay-open-after-selection="true"
        :show-results-on-focus="true"
        :multiple="true"
        name="types"
        label="Publicatietype(s)"
        placeholder="Selecteer types"
        :show-required-label="true"
        validation="required"
        outer-class="tw-flex-grow"
      />
      <FormulateInput
        type="submit"
        title="Actie(s) registreren"
        outer-class="tw-mt-6"
      >
        Actie(s) registreren
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { validEndDate } from '@/utils/helpers'
import { createProjectPublicationAction } from '@/services/projects'
import { createPropertyPublicationAction } from '@/services/properties'
import { successModal } from '@/modalMessages'

export default {
  name: 'PublicationActionForm',
  props: {
    property: {
      type: Object
    },
    project: {
      type: Object
    }
  },
  data () {
    return {
      key: 1
    }
  },
  methods: {
    validEndDate,
    async submit (data) {
      try {
        const { types, ...values } = data
        const response = Promise.all(
          types.map(type => {
            const payload = { type: type.id, ...values }
            if (this.property) {
              payload.property = this.property.id
              return createPropertyPublicationAction(this.property.id, payload)
            } else if (this.project) {
              return createProjectPublicationAction(this.project.id, payload)
            }
          })
        )
        this.key = Math.random()
        this.$emit('registered')
        successModal('Publicatieactie succesvol geregistreerd.', true)
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'publicationActionCreate')
      }
    }
  }
}
</script>
