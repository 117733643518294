<template>
  <FormulateForm
    #default="{ isLoading }"
    :key="key"
    v-model="sticker"
    name="stickerCreate"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <Modal ref="modal" title="Sticker aanmaken">
      <FormulateInput
        type="select"
        name="sticker_id"
        label="Sticker"
        placeholder="Selecteer een sticker"
        :options="stickerOptions"
        :show-required-label="true"
        validation="required"
      />
      <FormulateInput
        type="date"
        name="start_date"
        label="Startdatum"
        placeholder="YYYY-MM-DD"
        :show-required-label="true"
        validation="bail|required|date:YYYY-MM-DD"
      />
      <FormulateInput
        type="date"
        name="end_date"
        label="Einddatum"
        placeholder="YYYY-MM-DD"
        validation="bail|optional|date:YYYY-MM-DD|validEndDate"
        :validation-rules="{ validEndDate }"
        :validation-messages="{
          validEndDate:
            'Einddatum kan niet voor de startdatum vallen.',
        }"
      />
      <FormulateInput
        v-if="propId"
        type="text"
        name="url"
        label="URL"
        placeholder="URL"
        validation="optional"
      />

      <template #footer>
        <FormulateErrors />
        <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i
              :class="[
                'fas tw-mr-2',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
          Sticker opslaan
          </FormulateInput>
          <FormulateInput
            type="button"
            data-dismiss="modal"
            :input-class="['tw-bg-error tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i class="fa fa-times tw-mr-2"/> Annuleren
          </FormulateInput>
        </div>
      </template>
    </Modal>
  </FormulateForm>
</template>

<script>
import Modal from '@/components/iam/Modal'
import { successModal } from '@/modalMessages'
import { createPropertySticker, getStickerTypes } from '@/services/properties'
import { createProjectSticker } from '@/services/projects'
import { validEndDate } from '@/utils/helpers'

export default {
  name: 'CreateStickerModal',
  components: {
    Modal
  },
  props: {
    propId: {
      type: Number,
      required: false
    },
    projectId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      sticker: {},
      key: 1,
      stickerTypes: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    validEndDate,
    show () {
      this.$refs.modal.show()
    },
    async init () {
      const response = await getStickerTypes()
      this.stickerTypes = response.data?.results
      return response
    },
    hide () {
      this.sticker = {}
      this.$refs.modal.hide()
    },
    async submit (data) {
      try {
        // When clearing the end_date field, the value is set to ""
        // Which is not accepted by the back-end
        if (data.end_date === '') delete data.end_date
        const response = this.propId
          ? await createPropertySticker(this.propId, data)
          : await createProjectSticker(this.projectId, data)
        const sticker = response.data
        this.hide()
        this.$emit('reload')
        this.key = Math.random()
        successModal('Sticker succesvol opgeslagen')
        return sticker
      } catch (error) {
        this.$formulate.handle(error, 'stickerCreate')
      }
    }
  },
  computed: {
    stickerOptions () {
      return this.stickerTypes.map(type => { return { value: type.id, label: type.name } })
    }
  }
}
</script>
