<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="entityMarketingEdit"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <Modal ref="modal" title="Marketinginformatie bewerken">
      <FormulateInput
        type="checkbox"
        name="published"
        label="Gepubliceerd"
        :disabled="isDisabled"
      />
      <FormulateInput
        type="checkbox"
        name="is_visible_customer_portal"
        label="Zichtbaar op klantenportaal"
      />
      <FormulateInput
        type="url"
        name="video_url"
        label="YouTube-URL"
        placeholder="YouTube-URL"
      />
      <FormulateInput
        type="number"
        name="website_hits"
        label="Websitehits"
        placeholder="Websitehits"
      />
      <FormulateInput
        type="number"
        name="hmodh_sent"
        label="Verstuurde HMODH's"
        placeholder="Verstuurde HMODH's"
      />
      <template #footer>
        <FormulateErrors />
        <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i
              :class="[
                'fas tw-mr-2',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
          <FormulateInput
            type="button"
            data-dismiss="modal"
            :input-class="['tw-bg-error tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i class="fa fa-times tw-mr-2"/> Annuleren
          </FormulateInput>
        </div>
      </template>
    </Modal>
  </FormulateForm>
</template>

<script>
import Modal from '@/components/iam/Modal'
import { successModal } from '@/modalMessages'
import { updatePropertyMarketing } from '@/services/properties'
import { updateProjectMarketing } from '@/services/projects'

export default {
  name: 'EntityMarketingEditModal',
  components: {
    Modal
  },
  props: {
    property: {
      type: Object,
      required: true
    },
    propId: {
      type: Number,
      required: false
    },
    projectId: {
      type: Number,
      required: false
    },
    marketing: {
      type: Object
    }
  },
  data () {
    return {
      values: {}
    }
  },
  computed: {
    isDisabled () {
      // Property shouldn't be able to be published if it is currently unpublished
      // and the property's status is:
      // - prospect (1)
      // - removed from portfolio (15)
      // - dossier preparation (16)
      // Always allow depublishing
      return !this.marketing?.published && [1, 15, 16].includes(this.property?.status)
    }
  },
  methods: {
    show () {
      const { website_hits, hmodh_sent } = this.marketing.statistics
      const { published, is_visible_customer_portal, video_url } = this.marketing
      this.values = { published, is_visible_customer_portal, video_url, website_hits, hmodh_sent }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async submit (data) {
      try {
        const statistics = {
          hmodh_sent: data.hmodh_sent,
          website_hits: data.website_hits
        }
        data.statistics = statistics
        const response = this.propId
          ? await updatePropertyMarketing(this.propId, data)
          : await updateProjectMarketing(this.projectId, data)
        this.hide()
        this.$emit('reloadMarketing')
        successModal('Marketinginformatie succesvol bewerkt.')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'entityMarketingEdit')
      }
    }
  }
}
</script>
