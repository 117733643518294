<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="publicationActionEdit"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="updatePubAction"
  >
    <Modal ref="modal" title="Pub actie bewerken">
      <span v-if="publicationAction && publicationAction.type" class="tw-text-xl tw-font-bold">
        {{ publicationAction.type.name_nl }}
      </span>
      <FormulateInput
        type="date"
        name="start_date"
        label="Startdatum"
        placeholder="YYYY-MM-DD"
        :show-required-label="true"
        validation="bail|required|date:YYYY-MM-DD|validEndDate"
        :validation-rules="{ validEndDate }"
        :validation-messages="{
          validEndDate:
            'Startdatum mag niet na de einddatum liggen.',
        }"
      />
      <FormulateInput
        type="date"
        name="end_date"
        label="Einddatum"
        placeholder="YYYY-MM-DD"
        validation="bail|optional|date:YYYY-MM-DD|validEndDate"
        :validation-rules="{ validEndDate }"
        :validation-messages="{
          validEndDate:
            'Einddatum kan niet voor de startdatum vallen.',
        }"
      />
      <FormulateInput
        type="textarea"
        name="custom_description_value"
        label="Extra tekst"
      />
      <template #footer>
        <FormulateErrors />
        <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
            help-position="before"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-check',
              ]"
            />
            Actie wijzigen
          </FormulateInput>
          <FormulateInput
            type="button"
            :input-class="['tw-bg-error tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
            @click="hide"
          >
            <i class="far fa-times tw-mr-2" /> Annuleren
          </FormulateInput>
        </div>
      </template>
    </Modal>
  </FormulateForm>
</template>

<script>
import Modal from '@/components/iam/Modal'
import { updatePropertyPublicationAction } from '@/services/properties'
import { updateProjectPublicationAction } from '@/services/projects'
import { successModal } from '@/modalMessages'
import { validEndDate } from '@/utils/helpers'

export default {
  name: 'PublicationActionEditModal',
  components: {
    Modal
  },
  props: {
    property: {
      type: Object,
      required: false
    },
    project: {
      type: Object,
      required: false
    },
    publicationAction: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      values: {}
    }
  },
  watch: {
    publicationAction (values) {
      const { start_date, end_date, custom_description_value } = values
      this.values = { start_date, end_date, custom_description_value }
    }
  },
  methods: {
    validEndDate,
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.values = {}
      this.$refs.modal.hide()
    },
    async updatePubAction (data) {
      let response = {}
      if (this.property) {
        response = await updatePropertyPublicationAction(this.property.id, this.publicationAction.id, data)
      } else {
        response = await updateProjectPublicationAction(this.project.id, this.publicationAction.id, data)
      }
      successModal('De pub actie is succesvol gewijzigd')
      this.$emit('reloadPublicationActions')
      this.hide()
      return response
    }
  }
}
</script>
