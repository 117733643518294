var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{key:_vm.key,attrs:{"name":"stickerCreate","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('Modal',{ref:"modal",attrs:{"title":"Sticker aanmaken"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('FormulateErrors'),_c('div',{staticClass:"tw-flex tw-flex-row tw-space-x-4 tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":['tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto']}},[_c('i',{class:[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]}),_vm._v(" Sticker opslaan ")]),_c('FormulateInput',{attrs:{"type":"button","data-dismiss":"modal","input-class":['tw-bg-error tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto']}},[_c('i',{staticClass:"fa fa-times tw-mr-2"}),_vm._v(" Annuleren ")])],1)]},proxy:true}],null,true)},[_c('FormulateInput',{attrs:{"type":"select","name":"sticker_id","label":"Sticker","placeholder":"Selecteer een sticker","options":_vm.stickerOptions,"show-required-label":true,"validation":"required"}}),_c('FormulateInput',{attrs:{"type":"date","name":"start_date","label":"Startdatum","placeholder":"YYYY-MM-DD","show-required-label":true,"validation":"bail|required|date:YYYY-MM-DD"}}),_c('FormulateInput',{attrs:{"type":"date","name":"end_date","label":"Einddatum","placeholder":"YYYY-MM-DD","validation":"bail|optional|date:YYYY-MM-DD|validEndDate","validation-rules":{ validEndDate: _vm.validEndDate },"validation-messages":{
        validEndDate:
          'Einddatum kan niet voor de startdatum vallen.',
      }}}),(_vm.propId)?_c('FormulateInput',{attrs:{"type":"text","name":"url","label":"URL","placeholder":"URL","validation":"optional"}}):_vm._e()],1)]}}]),model:{value:(_vm.sticker),callback:function ($$v) {_vm.sticker=$$v},expression:"sticker"}})}
var staticRenderFns = []

export { render, staticRenderFns }