<template>
  <div>
    <div class="row" v-if="marketing">
      <div class="col-sm-4">
        <PortalPublications
          ref="portalPublications"
          :project="project"
          :property="property"
          :marketing="marketing"
        />

        <PanelBox title="Opties">
          <span slot="toolbar">
              <a type="button" class="toolbar-button" @click="showEntityMarketingEditModal">
                <i class="fa fa-pencil" /> Bewerken
              </a>
          </span>
          <dl class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-text-xs">
            <dt>Gepubliceerd</dt>
            <dd>
              <span>{{ marketing.published ? 'Ja' : 'Nee' }}</span>
            </dd>
            <dt>Zichtbaar op klantenportaal</dt>
            <dd>
              <span v-if="marketing.is_visible_customer_portal && (project || (property && property.status !== 1 && property.status !== 15))">
                Ja
                <a :href="customerPortalUrl" target="_blank">
                  <i class="fa fa-link tw-ml-1" title="Klantenportaal als eigenaar bekijken" />
                </a>
              </span>
              <span v-else>Nee</span>
            </dd>
            <dt>YouTube-link</dt>
            <dd>
              <a :href="marketing.video_url" target="_blank" v-if="marketing.video_url">Bekijk YouTube video</a>
              <span v-else>-</span>
            </dd>
            <dt>Websitehits</dt>
            <dd>
              <template v-if="marketing.statistics">
                {{ marketing.statistics.website_hits }}
              </template>
              <template v-else>Niet ingegeven</template>
            </dd>
            <dt>HMODH's verstuurd</dt>
            <dd>
              <template v-if="marketing.statistics">
                {{ marketing.statistics.hmodh_sent }}
              </template>
              <template v-else> Niet ingegeven</template>
            </dd>
          </dl>
        </PanelBox>
      </div>
      <div class="col-sm-8">
        <PanelBox title="Publicatieacties">
          <div id="app-entity-publication-actions">
            <PublicationActionForm
              ref="pubActionCreateForm"
              :property="property"
              :project="project"
              @registered="loadPubActions"
            />
            <DataTable
              :loading="loading"
              :headers="headers"
              :infinite-scroll="true"
              v-bind="publicationActions"
              class="tw--mx-5 tw-shadow-none"
              @infiniteLoad="infiniteLoad"
            >
              <template #item-period="{ item }">
                {{ item.start_date | date-day }}
                <span v-if="item.end_date"> tem  {{ item.end_date | date-day }}</span>
              </template>

              <template #item-description="{ item }">
                <span v-if="item">{{ item.type.description_nl }} {{ item.custom_description_value }}</span>
              </template>

              <template #item-actions="{ item }">
                <div class="tw-flex">
                  <button
                    type="button"
                    title="Bewerken"
                    class="action tw-bg-warning"
                    @click="showPublicationActionEditModal(item)"
                  >
                    <i class="far fa-pencil"/>
                  </button>
                  <button
                    type="button"
                    title="Verwijder publicatie"
                    class="action tw-bg-danger"
                    @click="removePubAction(item)"
                  >
                    <i class="far fa-trash"/>
                  </button>
                </div>
              </template>
            </DataTable>
            <PublicationActionEditModal
              ref="publicationActionEditModal"
              :property="property"
              :project="project"
              :publication-action="selectedPubAction"
              @reloadPublicationActions="loadPubActions"
            />
          </div>
        </PanelBox>
        <div class="col-sm-6 tw-pl-0 tw-pr-4">
          <PanelBox title="Stickers">
            <span slot="toolbar">
              <a class="toolbar-button" @click="showCreateStickerModal">
                <i class="fa fa-plus" /> nieuw
              </a>
            </span>
            <table class="table table-condensed table-hover">
              <thead>
                <tr>
                  <th>Sticker</th>
                  <th>Datum</th>
                  <th>URL</th>
                  <th />
                </tr>
              </thead>
              <tr v-for="sticker in stickers" :key="sticker.id">
                <td class="tw-align-middle">{{ sticker.sticker.name }}</td>
                <td class="tw-p-2">
                  {{ sticker.start_date | date-day}}
                  <span v-if="sticker.end_date">
                    <br>
                    Tot<br>
                    {{ sticker.end_date | date-day }}
                  </span>
                </td>
                <td class="tw-p-2">
                  <div v-if="sticker.url" class="tooltip-parent">
                    <a :href="sticker.url">
                      <i class="fa fa-chain" />
                    </a>
                    <span class="tooltip-text tw-overflow-hidden">
                      {{ sticker.url }}
                    </span>
                  </div>
                </td>
                <td class="tw-p-2">
                  <button type="button" class="btn btn-danger btn-xs" @click="deleteSticker(sticker.id)">
                    <i class="fa fa-trash" />
                  </button>
                </td>
              </tr>
            </table>
          </PanelBox>
        </div>
        <div class="col-sm-6 no-padding">
          <PanelBox title="Evenementen">
            <span slot="toolbar">
              <a class="toolbar-button" @click="showCreateEventModal">
                <i class="fa fa-plus" /> nieuw
              </a>
            </span>
            <table class="table table-condensed table-hover">
              <thead>
                <tr>
                  <th>Evenement</th>
                  <th>Datum</th>
                  <th>URL</th>
                  <th />
                </tr>
              </thead>
              <tr :key="event.id" v-for="event in events">
                <td class="tw-p-2">
                  {{ event.event_type.name_nl }}
                </td>
                <td class="tw-p-2">
                  {{ event.start_date | datetime}}
                  <span v-if="event.end_date">
                    <br>
                    Tot<br>
                    {{ event.end_date | datetime }}
                  </span>
                </td>
                <td class="tw-p-2">
                  <div v-if="event.url" class="tooltip-parent">
                    <a :href="event.url">
                      <i class="fa fa-chain" />
                    </a>
                    <span class="tooltip-text tw-overflow-hidden">
                      {{ event.url }}
                    </span>
                  </div>
                </td>
                <td class="tw-p-2">
                  <a @click="deleteEvent(event.id)" class="btn btn-danger btn-xs">
                    <i class="fa fa-trash" />
                  </a>
                </td>
              </tr>
            </table>
          </PanelBox>
        </div>
      </div>
      <CreateStickerModal
        ref="createStickerModal"
        :propId="propId"
        :projectId="projectId"
        @reload="loadStickers"
      />
      <CreateEventModal
        ref="createEventModal"
        :propId="propId"
        :projectId="projectId"
        @reload="loadEvents"
      />
      <EntityMarketingEditModal
        ref="entityMarketingEditModal"
        :property="property"
        :propId="propId"
        :projectId="projectId"
        :marketing="marketing"
        @reloadMarketing="reloadMarketing"
      />
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import EventBus from '@/components/iam/bus'
import PanelBox from '@/components/iam/PanelBox'
import PublicationActionEditModal from '@/components/properties/PublicationActionEditModal'
import PublicationActionForm from '@/components/properties/PublicationActionForm'
import PortalPublications from '@/components/properties/PortalPublications'
import CreateStickerModal from '@/components/properties/CreateStickerModal'
import CreateEventModal from '@/components/properties/CreateEventModal'
import EntityMarketingEditModal from '@/components/properties/EntityMarketingEditModal'

import { mapActions, mapGetters } from 'vuex'
import { startLoadingModal, errorModal, successModal } from '@/modalMessages'
import {
  getPropertyPublicationActions,
  deletePropertyPublicationAction,
  getPropertyStickers,
  deletePropertySticker,
  getPropertyEvents,
  deletePropertyEvent,
  getPropertyMarketing
} from '@/services/properties'
import {
  getProjectPublicationActions,
  deleteProjectPublicationAction,
  getProjectStickers,
  getProjectEvents,
  deleteProjectEvent,
  deleteProjectSticker,
  getProjectMarketing
} from '@/services/projects'

export default {
  components: {
    PanelBox,
    PortalPublications,
    PublicationActionForm,
    PublicationActionEditModal,
    DataTable,
    CreateStickerModal,
    CreateEventModal,
    EntityMarketingEditModal
  },
  name: 'MarketingPanel',
  data () {
    return {
      activeEditConfig: {},
      activeObject: {},
      selectedPubAction: {},
      loading: false,
      // The object publicationActions includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      publicationActions: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      stickers: [],
      events: [],
      marketing: null
    }
  },
  created () {
    this.init()
  },
  /* Remove emitter binding when component is about to be destroyed elsewise it is bound multiple times when we toggle
    back and forwards */
  beforeDestroy () {
    EventBus.$off('properties-publication-action-remove')
  },
  computed: {
    ...mapGetters('properties', ['getPropertyById', 'getProjectById']),

    headers () {
      return [
        { value: 'type.name_nl', text: 'Type' },
        { value: 'period', text: 'Periode', renderComponent: 'properties-publication-action-period' },
        { value: 'description', text: 'Beschrijving' },
        { value: 'actions', text: 'Actie' }
      ]
    },
    property () {
      return this.$route.meta.entity_type === 'property' ? this.getPropertyById(this.$route.params.id) : null
    },
    project () {
      return this.$route.meta.entity_type === 'project' ? this.getProjectById(this.$route.params.id) : null
    },
    propId () {
      return this.$route.meta.entity_type === 'property' ? parseInt(this.$route.params.id) : null
    },
    projectId () {
      return this.$route.meta.entity_type === 'project' ? parseInt(this.$route.params.id) : null
    },
    extraData () {
      return {
        property: this.property,
        project: this.project
      }
    },
    customerPortalUrl () {
      const entity = this.property || this.project
      return entity.customer_portal_url
    }
  },
  methods: {
    ...mapActions('properties', [
      'deletePublicationActionForProperty',
      'deletePublicationActionForProject',
      'loadProperty',
      'loadProject'
    ]),

    showPublicationActionEditModal (pubAction) {
      this.selectedPubAction = pubAction
      this.$refs.publicationActionEditModal.show()
    },

    showCreateStickerModal () {
      this.$refs.createStickerModal.show()
    },

    showCreateEventModal () {
      this.$refs.createEventModal.show()
    },

    showEntityMarketingEditModal () {
      this.$refs.entityMarketingEditModal.show()
    },

    async init () {
      const promises = [this.loadPubActions(), this.loadStickers(), this.loadEvents(), this.loadEntityMarketing()]
      const response = await Promise.all(promises)
      EventBus.$on('properties-publication-action-remove', async (action) => {
        await this.removePubAction(action)
      })
      return response
    },
    async loadEntityMarketing () {
      const response = this.property ? await getPropertyMarketing(this.property.id) : await getProjectMarketing(this.project.id)
      this.marketing = response.data
      return response
    },
    async loadEvents () {
      const response = this.property
        ? await getPropertyEvents(this.property.id)
        : await getProjectEvents(this.project.id)
      this.events = response.data?.results
      return response
    },
    async loadStickers () {
      const response = this.property
        ? await getPropertyStickers(this.property.id)
        : await getProjectStickers(this.project.id)
      this.stickers = response.data?.results
      return response
    },
    async removePubAction (record) {
      try {
        startLoadingModal('Publicatieactie verwijderen')
        const response = this.property
          ? await deletePropertyPublicationAction(this.property.id, record.id)
          : await deleteProjectPublicationAction(this.project.id, record.id)
        await this.loadPubActions()
        successModal('Publicatieactie is successvol verwijderd')
        return response
      } catch (error) {
        console.error(error)
        errorModal('Publicatieactie kon niet verwijderd worden. Probeer later opnieuw.')
      }
    },

    async loadPubActions (payload) {
      try {
        // Re-inits and fetches the pub actions again
        this.loading = true
        const pubActions = await this.fetchPubActions(payload)
        this.publicationActions = pubActions
        this.loading = false
        return pubActions
      } catch (error) {
        errorModal('Fout bij het laden van publicatieacties, probeer het opnieuw.')
      }
    },
    async fetchPubActions (payload) {
      const response = this.property
        ? await getPropertyPublicationActions({ propertyId: this.property.id, ...payload })
        : await getProjectPublicationActions({ projectId: this.project.id, ...payload })
      return response.data
    },
    async infiniteLoad ($infinite) {
      try {
        const next = this.publicationActions.next
        if (!next) {
          // No more data and state is loaded
          if (this.publicationActions.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const pubActions = await this.fetchPubActions({ url: next })
        const results = [...this.publicationActions.results, ...pubActions.results]
        this.publicationActions = { ...pubActions, results }
        $infinite.loaded()
        return pubActions
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async deleteSticker (id) {
      startLoadingModal('Bezig met verwijderen van sticker.')
      try {
        if (this.property) {
          await deletePropertySticker(this.property.id, id)
        }
        if (this.project) {
          await deleteProjectSticker(this.project.id, id)
        }
        successModal('De sticker werd verwijderd.', true)
        return await this.loadStickers()
      } catch (e) {
        console.error(e)
        errorModal('Er ging iets verkeerd bij het verwijderen van de sticker', true)
      }
    },
    async deleteEvent (id) {
      startLoadingModal('Bezig met verwijderen van sticker.')
      try {
        if (this.property) {
          await deletePropertyEvent(this.property.id, id)
        }
        if (this.project) {
          await deleteProjectEvent(this.project.id, id)
        }
        successModal('De sticker werd verwijderd.', true)
        return await this.loadEvents()
      } catch (e) {
        console.error(e)
        errorModal('Er ging iets verkeerd bij het verwijderen van het evenement', true)
      }
    },
    async loadEntity () {
      const response = this.property ? await this.loadProperty(this.property.id) : await this.loadProject(this.project.id)
      return response
    },
    async reloadMarketing () {
      const promises = [this.loadEntityMarketing(), this.$refs.portalPublications.loadPublicationData(), this.loadEntity()]
      await Promise.all(promises)
    }
  }
}
</script>

<style scoped>
  .tooltip-parent {
    position: relative;
    display: inline-block;
  }

  /* Tooltip text */
  .tooltip-parent .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: white;
    color: dimgray;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    border-width:1px;
    border-style:solid;
    border-color:grey;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip-parent:hover .tooltip-text {
    visibility: visible;
  }
</style>
