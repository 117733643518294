var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{attrs:{"name":"publicationActionEdit","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.updatePubAction},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('Modal',{ref:"modal",attrs:{"title":"Pub actie bewerken"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('FormulateErrors'),_c('div',{staticClass:"tw-flex tw-flex-row tw-space-x-4 tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":['tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto'],"help-position":"before"}},[_c('i',{class:[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-check' ]}),_vm._v(" Actie wijzigen ")]),_c('FormulateInput',{attrs:{"type":"button","input-class":['tw-bg-error tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto']},on:{"click":_vm.hide}},[_c('i',{staticClass:"far fa-times tw-mr-2"}),_vm._v(" Annuleren ")])],1)]},proxy:true}],null,true)},[(_vm.publicationAction && _vm.publicationAction.type)?_c('span',{staticClass:"tw-text-xl tw-font-bold"},[_vm._v(" "+_vm._s(_vm.publicationAction.type.name_nl)+" ")]):_vm._e(),_c('FormulateInput',{attrs:{"type":"date","name":"start_date","label":"Startdatum","placeholder":"YYYY-MM-DD","show-required-label":true,"validation":"bail|required|date:YYYY-MM-DD|validEndDate","validation-rules":{ validEndDate: _vm.validEndDate },"validation-messages":{
        validEndDate:
          'Startdatum mag niet na de einddatum liggen.',
      }}}),_c('FormulateInput',{attrs:{"type":"date","name":"end_date","label":"Einddatum","placeholder":"YYYY-MM-DD","validation":"bail|optional|date:YYYY-MM-DD|validEndDate","validation-rules":{ validEndDate: _vm.validEndDate },"validation-messages":{
        validEndDate:
          'Einddatum kan niet voor de startdatum vallen.',
      }}}),_c('FormulateInput',{attrs:{"type":"textarea","name":"custom_description_value","label":"Extra tekst"}})],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})}
var staticRenderFns = []

export { render, staticRenderFns }